import { defineNuxtRouteMiddleware } from '#app';
import { CITY_LIST } from '~/constants';
import type { TCity } from '~/types/api';

export default defineNuxtRouteMiddleware((to) => {
  const id = to.params.id as string;

  if (!CITY_LIST.includes(id as TCity)) {
    return navigateTo('/', { redirectCode: 301 });
  }
});
